<template>
  <b-popover
    :target="'popover-payments-' + itemKey"
    triggers="hover"
    placement="top"
  >
    <div>
      <div class="text-left">
        <div class="text-primary font-weight-bold font-small-2">
          Fatura Tutarı
        </div>
        <div>
          {{ dataItem.payment_invoice_amount | toCurrency }} TL
        </div>
        <hr>
        <div class="text-primary font-weight-bold font-small-2">
          Ödeme Tutarı
        </div>
        <div>
          {{ dataItem.payment_amount | toCurrency }} TL
        </div>
        <hr>
        <div class="text-primary font-weight-bold font-small-2">
          Bakiye
        </div>
        <div>
          {{ dataItem.payment_balance | toCurrency }} TL
        </div>
        <hr>
        <div class="text-primary font-weight-bold font-small-2">
          Ödeme Tarihi (Ortalama)
        </div>
        <div>
          {{ dataItem.payment_avg_date? moment(dataItem.payment_avg_date).format('DD.MM.YYYY') : '-' }}
        </div>
      </div>
    </div>
  </b-popover>
</template>
<script>
import {
  VBPopover, BPopover,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'PaymentPopover',
  components: {
    BPopover,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
    dataItem: {
      type: Object,
      required: true,
    },
    itemKey: {
      type: String,
      required: true,
    },
  },
}
</script>
