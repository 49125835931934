<template>
  <b-modal
    v-model="paymentsDetail.modalStatus"
    centered
    size="lg"
    hide-footer
    no-close-on-backdrop
    title="Ödeme Detayı"
  >
    <b-card-body class="p-0">
      <pre-loading v-if="loading" />
      <template v-else>
        <b-table-simple
          v-if="paymentsDetail.dataList.length"
          small
          bordered
        >
          <b-thead>
            <b-tr>
              <b-th>FATURA TARİHİ</b-th>
              <b-th colspan="3">
                {{ moment(paymentsDetail.dataList[0].fatura_tarihi).format('DD.MM.YYYY') }}
              </b-th>
            </b-tr>
            <b-tr>
              <b-th>FATURA TUTARI</b-th>
              <b-th colspan="3">
                {{ paymentsDetail.dataList[0].fatura_tutari | toCurrency }} TL
              </b-th>
            </b-tr>
            <b-tr>
              <b-th>KALAN BAKİYE</b-th>
              <b-th colspan="3">
                <div :class="paymentsDetail.dataList[0].kalan_tutar > 0? 'text-danger' : ''">
                  {{ paymentsDetail.dataList[0].kalan_tutar | toCurrency }} TL
                </div>
              </b-th>
            </b-tr>
            <b-tr>
              <b-th>CARİ</b-th>
              <b-th>ARAÇ</b-th>
              <b-th>ÖDEME TARİHİ</b-th>
              <b-th>ÖDENEN TUTAR</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="item in paymentsDetail.dataList"
              :key="item.id"
            >
              <b-td>
                <div class="font-small-2 text-primary">
                  {{ item.kodu }}
                </div>
                <div class="font-weight-bold font-small-1">
                  {{ item.unvani }}
                </div>
              </b-td>
              <b-td>
                <div class="font-small-1">
                  {{ item.marka_model }}
                </div>
                <div class="font-small-2 text-primary">
                  {{ item.sasi }}
                </div>
              </b-td>
              <b-td>
                <div class="font-small-2">
                  {{ item.odeme_tarihi? moment(item.odeme_tarihi).format('DD.MM.YYYY') : '-' }}
                </div>
              </b-td>
              <b-td>
                <div class="font-small-2">
                  {{ item.odenen_tutar | toCurrency }} TL
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </template>
    </b-card-body>
  </b-modal>
</template>
<script>
import {
  BModal, BCardBody, BTableSimple, BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'PaymentsDetailModal',
  components: {
    BModal,
    BCardBody,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    PreLoading,
  },
  computed: {
    loading() {
      return this.$store.getters['salesManagerGpr/getPaymentsDetailLoading']
    },
    paymentsDetail() {
      return this.$store.getters['salesManagerGpr/getPaymentsDetail']
    },
  },
}
</script>
