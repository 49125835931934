<template>
  <div>
    <b-row class="mb-2">
      <b-col
        cols="12"
        md="12"
        class="text-right"
      >
        <small>Rapor verisi en son {{ moment(getFinancialCosts.syncDate).format("DD.MM.YYYY HH:mm:ss") }} tarihinde güncellendi.</small>
        <div>
          <b-button
            variant="primary"
            size="sm"
            :disabled="syncLoading"
            @click="syncReportData"
          >
            <template v-if="syncLoading">
              <b-spinner small />
            </template>
            {{ syncbuttonText }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="CheckCircleIcon" />
          <span>Satılmış Araçlar</span>
        </template>
        <financial-cost-filter :get-filtered-data="setFinancialCosts" />
        <loading v-if="loadingReport" />
        <template v-else>
          <manager-stock-g-p-r-total-card
            v-if="getFinancialCosts.totals"
            :grand-total="getFinancialCosts.totals"
          />
          <template v-for="(brand,key) in getFinancialCosts.brands">
            <div
              v-if="brand.data.length >0"
              :key="key"
            >
              <div class="font-weight-bold font-medium-2 my-2 bg-light-primary p-1 rounded">
                {{ brand.title }}
              </div>
              <stock-manager-g-p-r-statistics
                :statistics-data="brand.statistics"
                class="mb-2"
              />
              <car-list-table
                :totals-data="brand.totals"
                :period-data="getFinancialCosts.periods"
                :car-data="brand.data"
              />
            </div>
          </template>
        </template>
        <payments-detail-modal />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="BoxIcon" />
          <span>Stoktaki Araçlar</span>
        </template>
        <stock-financial-cost-card />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BTab, BTabs, BRow, BCol, BButton, BSpinner,
} from 'bootstrap-vue'
import FinancialCostFilter from '@/views/Reports/Sales/ManagerGPR/FinancialCosts/Components/FilterCard.vue'
import Loading from '@/views/Reports/Sales/ManagerGPR/FinancialCosts/Components/LoadingReport.vue'
import CarListTable from '@/views/Reports/Sales/ManagerGPR/FinancialCosts/Components/carList.vue'
import StockManagerGPRStatistics from '@/views/Reports/Sales/ManagerGPR/FinancialCosts/Components/Statistics.vue'
import ManagerStockGPRTotalCard from '@/views/Reports/Sales/ManagerGPR/FinancialCosts/Components/TotalCard.vue'
import StockFinancialCostCard from '@/views/Reports/Sales/ManagerGPR/stockFinancialCosts.vue'
import PaymentsDetailModal from '@/views/Reports/Sales/ManagerGPR/FinancialCosts/Components/PaymentsDetailModal.vue'

export default {
  name: 'FinancialCostCard',
  components: {
    StockFinancialCostCard,
    BTab,
    BTabs,
    BRow,
    BCol,
    BButton,
    BSpinner,
    ManagerStockGPRTotalCard,
    StockManagerGPRStatistics,
    CarListTable,
    Loading,
    FinancialCostFilter,
    PaymentsDetailModal,
  },
  data() {
    return {
      syncbuttonText: 'Rapor verisini güncelle',
    }
  },
  computed: {
    getFinancialFilter() {
      return this.$store.getters['salesManagerGpr/getFinancialFilterParams']
    },
    loadingReport() {
      return this.$store.getters['salesManagerGpr/getFinancialLoading']
    },
    getFinancialCosts() {
      return this.$store.getters['salesManagerGpr/getFinancialCosts']
    },
    syncLoading() {
      return this.$store.getters['salesManagerGpr/getSyncDataLoading']
    },
  },
  created() {
    this.setFinancialCosts()
  },
  methods: {
    setFinancialCosts() {
      this.getFinancialFilter.reportType = 'sales'
      this.$store.dispatch('salesManagerGpr/financialCosts', this.getFinancialFilter)
    },
    syncReportData() {
      this.syncbuttonText = 'Güncelleniyor..'
      this.$store.dispatch('salesManagerGpr/reportDataSync')
        .then(response => {
          if (response.status) {
            const config = {
              icon: 'success',
              title: 'İşlem Başarılı!',
              text: response.message,
              html: null,
              buttonText: 'Kapat',
            }
            this.setFinancialCosts()
            this.sweetAlert(config)
          } else {
            const config = {
              icon: 'danger',
              title: 'İşlem Başarısız!',
              text: 'İşlem başarısız oldu.',
              html: null,
              buttonText: 'Kapat',
            }
            this.sweetAlert(config)
          }
          this.syncbuttonText = 'Rapor verisini güncelle'
        })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
